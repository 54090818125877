//
// comment.scss
// Dashkit component
//

// Comment
//
// General styles

.comment {
  margin-bottom: $comment-margin-bottom;
}

.comment-body {
  display: inline-block;
  padding: $comment-body-padding-y $comment-body-padding-x;
  background-color: $comment-body-bg;
  border-radius: $comment-body-border-radius;
}

.comment-time {
  display: block;
  margin-bottom: $comment-time-margin-bottom;
  font-size: $comment-time-font-size;
  color: $comment-time-color;
}

.comment-text {
  font-size: $comment-body-font-size;
}

.comment-text:last-child {
  margin-bottom: 0;
}