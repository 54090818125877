// 
// badge.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides =====================================
//

.badge {
  vertical-align: middle;
}

// Quick fix for badges in buttons
.btn .badge {
  top: -2px;
}

// Pills

.badge.rounded-pill {
  padding-right: $border-radius-pill-padding-x;
  padding-left: $border-radius-pill-padding-x;
}

// Text color
//
// Replacing the default white text color

@each $color, $value in $theme-colors {
  .badge.bg-#{$color} {
    color: color-contrast($value);
  }
}


// 
// Theme =====================================
//

// Creates the "soft" badge variant
@each $color, $value in $theme-colors {
  .badge.bg-#{$color}-soft {
    @include badge-variant-soft(shift-color($value, $bg-soft-scale), $value);
  }
}
