// 
// breadcrumb.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides =====================================
//

.breadcrumb-item {

  + .breadcrumb-item::before {
    content: "\e930";
    align-self: center;
    font-size: .8rem;
    font-family: "Feather";
    color: $breadcrumb-divider-color;
  }
}


// 
// Theme =====================================
//

// Small
//
// Reduces font size

.breadcrumb-sm {
  font-size: $breadcrumb-font-size-sm;
}


// Overflow
//
// Allows the breadcrumb to be overflown horizontally

.breadcrumb-overflow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.breadcrumb-overflow .breadcrumb-item {
  white-space: nowrap;
}
