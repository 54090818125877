//
// choices.scss
// Choices plugin overrides
//

.choices {
  position: relative;
  cursor: pointer;
}

.choices:focus {
  outline: none;
}

.choices::after {
  display: none;
}


// Dropdown menu

.choices > .dropdown-menu {
  width: 100%;
}

.choices > .dropdown-menu .form-control {
  width: calc(100% - #{$input-padding-x} * 2);
  margin: $dropdown-item-padding-y $input-padding-x;
}

.choices > .dropdown-menu .dropdown-item {
  padding-left: $input-padding-x;
  padding-right: $input-padding-x;
}

.choices > .dropdown-menu .dropdown-item.is-highlighted {
  color: $dropdown-link-hover-color;
}

// Sizing

.choices > .form-control-sm + .dropdown-menu,
.choices > .form-select-sm + .dropdown-menu {
  min-width: 100%;
  width: auto;
}

.choices > .form-control-sm + .dropdown-menu .dropdown-item,
.choices > .form-select-sm + .dropdown-menu .dropdown-item {
  padding-right: $input-padding-x-sm;
  padding-left: $input-padding-x-sm;
  font-size: $font-size-sm;
}

.choices > .form-control-sm + .dropdown-menu .form-control,
.choices > .form-select-sm + .dropdown-menu .form-control {
  margin-right: $input-padding-x-sm;
  margin-left: $input-padding-x-sm;
}


// Placeholder

.choices__placeholder {
  color: $input-placeholder-color;
  opacity: 1;
}


// Open

.choices.is-open > .form-control,
.choices.is-open > .form-select {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.choices.is-open > .form-control + .dropdown-menu,
.choices.is-open > .form-select + .dropdown-menu {
  margin-top: -$dropdown-border-width;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}


// Multiple

.choices__list--multiple + .form-control {
  display: inline-block;
  min-height: 0;
  width: auto;
  padding: 0;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: $input-font-size;
}

.form-control-lg + .choices__list--multiple + .form-control,
.form-select-lg + .choices__list--multiple + .form-control {
  font-size: $input-font-size-lg;
}

.form-control-sm + .choices__list--multiple + .form-control,
.form-select-sm + .choices__list--multiple + .form-control {
  font-size: $input-font-size-sm;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple > .choices__item {
  display: inline-block;
  margin-right: .2rem;
  padding-right: .375rem;
  padding-left: .375rem;
  font-size: $font-size-sm;
  background-color: $light;
  border-radius: $border-radius-xs;
}

.choices__list--multiple > .choices__item > .choices__button {
  width: .5rem;
  margin-left: .5rem;
  padding: 0;
  outline: none;
  border-width: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(escape-svg("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='#{$body-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>"));
  background-size: 100% auto;
  text-indent: -9999px;
  opacity: .5;
  transition: $input-transition;
}

.choices__list--multiple > .choices__item:hover > .choices__button {
  opacity: 1;
}
