// Badge Mixins
//
// This is a custom mixin for badge-#{color}-soft variant of Bootstrap's .badge class

@mixin badge-variant-soft($bg, $color) {
  color: $color;

  &[href]:hover, &[href]:focus {
    background-color: darken($bg, 5%) !important;
  }
}
